<template>
  <LiefengContent>
    <template v-slot:title>广州市“社区随约服务网上驿站”用户数据统计</template>
    <template v-slot:contentArea>
     <div id="container">
    </div>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/chartindex');
import LiefengContent from "@/components/LiefengContent";
import { TMap } from './TMap'
export default {
  data() {
    return {

    };
  },
  mounted(){
       TMap('5MLBZ-M32WI-QIRGI-5C5UD-5M5W3-VFBUK').then(qq => {
            var map = new qq.maps.Map(document.getElementById("container"),{
                //地图的中心地理坐标
                center:new qq.maps.LatLng(39.916527,116.397128),zoom:8

            });

        });
  },
  methods: {
  },
  created() {

  },
  components: {
    LiefengContent,

  }
};
</script>

<style scoped lang='less'>
#container{
     min-width:600px;
    min-height:767px;
}
</style>
